import React, { useEffect, useState } from "react";
import Dashboard from "../../layouts/Dashboard";
import {
  Box,
  Grid,
  Typography,
  styled,
  Skeleton,
  Stack,
  IconButton,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as ExpandMoreIcon } from "../../../src/assets/images/Aos/accordianArrow.svg";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";
// import ReportBugBtn from "../../components/Button/ReportBugBtn";
// import LinearProgress, {
//     linearProgressClasses,
// } from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
import SubjectBtnBtmSheet from "../../components/Button/subjectBtnBtmSheet";
import IconComponent from "../../components/Icons";
// import BreadCrumbresComponent from "../../components/BreadCrumbsResponsive";
// import { AccordionBtmSheetRes } from "../../components/BottomDrawer/AccordionbtmSheet";
import BorderText from "../../components/Label/BorderText";
import ToastMessage from "../../components/ToastMessage";
import {
  getCourseRecordingClassID,
  // markAsComplete,
} from "../../services/api/subject";
import { useLocation } from "react-router-dom";
import { getToken } from "../../services/api/login";
import { ChevronLeft, ChevronRight } from "../../utils/icons";
// import Icons from "../../components/Icons";
import Icons from "../../components/Icons";
import StudentClassRecording from "../../components/Video/StudentClassRecording";

const StyledIconButton = styled(IconButton)({
  "&:hover": {
    backgroundColor: "transparent", // Disable hover color
    fill: "red",
  },
  padding: "2px",
  ":disabled": {
    cursor: "not-allowed",
    "pointer-events": "auto !important",
  },
});
const CustomTypography = styled(Typography)(
  ({ isEmpty }: { isEmpty: boolean }) => ({
    cursor: "default",
    border: "0px",
    fontWeight: "501",
    borderBottom: !isEmpty ? "1px solid" : "0",
    borderColor: "#FFA737",
    minWidth: isEmpty ? "35px" : "auto",
  })
);

export interface SubjectClassRecDetail {
  area_of_study: any;
  id: number;
  student_id: number;
  fk_subject_id: number;
  subject: string;
  subject_icon: null;
  subject_colour: string;
  mst_subject_group_id: number;
  sub_code: string;
  recordings: CRecordings;
  list_of_aos: ListOfAos[];
}

export interface CRecordings {
  id: any;
  name: string;
  classes: Classes[];
}

export interface Classes {
  id: number
  class_tutor: string
  class_day: string
  class_time: string
  class_location: string
  class_name: string
  class_id: number
  files: FileName[];
}

export interface FileName {
  id: number
  file_name: string
  file_url: string
  thumbnail_url: string
  transcript_path: string
  week_no: string
  publish_status: string
  fk_class_id: number
}

export interface ListOfAos {
  id: number;
  area_of_study: string;
}

export const ClassRecording = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const verifyAccess = getToken();
  const [subjectClassRecDetails, setSubjectClassRecDetails] = useState<
    Array<SubjectClassRecDetail>
  >([]);
  const [toast, setToast] = React.useState<{
    type: string;
    message: string;
  } | null>(null);
  // const [showRightPanel] = useState(false);
  const [clrecID, setClrecId] = useState<string | undefined>();
  const [bugRepId, setBugRepId] = useState("");
  const [expanded, setExpanded] = useState<Set<string>>(new Set());
  const [loadingData, setLoadingData] = useState(true);
  const [currentSubjectId, setCurrentSubjectId] = useState<
    string | undefined
  >();
  const [showRightPanel, setShowRightPanel] = useState(false);
  const [file_id, setFileId] = useState<any>();
  const [file_url, setFile_url] = useState<any>();
  const [thumbnail_url, setThumbnail_url] = useState<any>();
  const [transcript_path, setTranscript_path] = useState<any>();
  const [selectedItems, setSelectedItems] = useState<{
    [key: string]: string | null;
  }>({});
  const [selectedItem, setSelectedItem] = useState<string | null>("");
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const [selectedSubModule, setSelectedSubModule] = useState<any>({
    id: "",
    module: "",
  });
  const [page, setPage] = useState<{
    nextPage: { id: string; area_of_study: string; aos_code: string } | null;
    currentPage: { id: string; area_of_study: string; aos_code: string } | null;
    prevPage: { id: string; area_of_study: string; aos_code: string } | null;
  }>({ nextPage: null, prevPage: null, currentPage: null });

  const [selectedType, setSelectedType] = useState<string>("");
  useEffect(() => {
    console.log("selectedItemselectedItems", selectedItem, selectedItems);
  }, [selectedItem, selectedItems]);

  const handleChange =
    (panel: string) =>
      (event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
        const newExpanded = new Set<string>(expanded);
        if (isExpanded) {
          newExpanded.add(panel);
        } else {
          newExpanded.delete(panel);
        }
        setExpanded(newExpanded);
      };

  useEffect(() => {
    const cSubjectId = queryParams.get("sid");
    const crecId = queryParams.get("crecId");
    const classId = queryParams.get("classId") || undefined;
    const fileID = queryParams.get("fileID") || undefined;

    // Avoid updating crecId if it's the same
    if (clrecID !== crecId) {
      setClrecId(crecId !== null ? crecId : undefined);
    }
    // Avoid updating subject ID if it's the same
    if (currentSubjectId !== cSubjectId) {
      setCurrentSubjectId(cSubjectId !== null ? cSubjectId : undefined);
    }
    getCourseRecordingClassbyId(cSubjectId, crecId).then((response: any) => {
      // alert(JSON.stringify(response))
      const subjectAOSDetailData: SubjectClassRecDetail[] = response?.data?.data;

      if (!subjectAOSDetailData || subjectAOSDetailData.length === 0) {
        console.error("No data available");
        setLoadingData(false);
        return;
      }

      // Function to get details dynamically
      const getDynamicDetails = (classes: any[], specificClassId?: string, specificFileId?: string) => {
        if (!classes || classes.length === 0) return null;

        // Find the specific class if mentioned, otherwise take the first class
        const selectedClass = specificClassId
          ? classes.find((cls) => cls.class_id === specificClassId)
          : classes[0];

        if (!selectedClass || !selectedClass.files || selectedClass.files.length === 0) return null;

        // Find the specific file if mentioned, otherwise take the first file
        const selectedFile = specificFileId
          ? selectedClass.files.find((file: any) => `${file.id}` === specificFileId)
          : selectedClass.files[0];

        if (!selectedFile) return null;

        return {
          classId: selectedClass.id,
          fileId: selectedFile.id,
          fileUrl: selectedFile.file_url,
          thumbnailUrl: selectedFile.thumbnail_url,
          transcriptUrl: selectedFile.transcript_path
        };
      };

      // Fetch or set data only if necessary
      if (subjectAOSDetailData.length === 0 || clrecID !== crecId) {
        // Fetch data when subjectAOSDetails is empty or AOS ID changes
        getCourseRecordingClassbyId(cSubjectId, crecId).then((response: any) => {
          const subjectAOSDetailData = response?.data?.data;
          if (subjectAOSDetailData.length > 0) {
            SetRequiredData(subjectAOSDetailData, classId, crecId, fileID);
          }
        });
      } else if (subjectAOSDetailData.length > 0 && fileID) {
        // Use available data if it's already fetched
        SetRequiredData(subjectAOSDetailData, classId, crecId, fileID);
      }

      // Call the helper function to get details
      const details = getDynamicDetails(
        subjectAOSDetailData[0].recordings.classes, classId, fileID
      );

      if (details) {
        setFileId(details.fileId);
        setFile_url(details.fileUrl);
        setThumbnail_url(details.thumbnailUrl)
        setTranscript_path(details.transcriptUrl)
        setExpanded(new Set<string>([`panel${details.classId}`]));
        setSelectedItems({
          [`panel${details.classId}`]: `${details.fileId}`,
        });
      } else {
        console.error("No valid file details found");
      }

      setSubjectClassRecDetails(subjectAOSDetailData);
      setLoadingData(false);

      console.log("subjectAOSDetailData", subjectAOSDetailData);
    });

  }, [location.search]); // Only re-run when URL changes
  // useEffect(()=>{console.log("selectedItems",selectedItems)},[selectedItems])
  const getCourseRecordingClassbyId = async (
    fk_subject_id: any,
    class_id: any
  ) => {
    try {
      const payload = {
        subject_id: fk_subject_id,
        class_id: class_id,
      };
      let response
      if (subjectClassRecDetails.length === 0) {
        response = await getCourseRecordingClassID(
          payload,
          verifyAccess.token
        );
        return response
      } else {
        return {
          data: {
            data: subjectClassRecDetails
          }
        }
      }
      // }
    } catch (error) {
      setLoadingData(false);
      console.error("Error fetching subject aos details by ID:", error);
    }
  };

  const navigate = useNavigate();
  const handleClickSub = (subCode: string) => {
    navigate(
      `/subject/${subCode}?sid=${subjectClassRecDetails[0]?.fk_subject_id}`
    );
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      onClick={() => handleClickSub(subjectClassRecDetails[0]?.sub_code)}
      sx={{
        fontSize: 12,
        color: "#333",
        fontFamily: "Inter",
        fontWeight: 600,
        ":hover": {
          cursor: "pointer",
        },
      }}
    >
      {subjectClassRecDetails && subjectClassRecDetails[0]?.sub_code}
    </Link>,
    <Typography
      key="3"
      sx={{
        fontSize: 12,
        color: "#333",
        fontFamily: "Inter",
        fontWeight: 500,
      }}
    >
      AOS 2 [name]
    </Typography>,
  ];

  const theme = useTheme();

  const handleExpandAll = () => {
    const allPanelIds = new Set<string>(
      subjectClassRecDetails[0].recordings?.classes?.map(
        (item: any) => `panel${item.id}`
      )
    );
    setExpanded(allPanelIds);
  };

  const handleCollapseAll = () => {
    setExpanded(new Set());
  };

  function hexToRgb(hex: string): number[] | null {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (!result) {
      return null;
    }
    return [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16),
    ];
  }

  const checkDisable = (type: string) => {
    const list_of_aos =
      subjectClassRecDetails && subjectClassRecDetails[0]?.list_of_aos;
    const currentAosID = queryParams.get("crecId");
    const filterdlist = list_of_aos.filter(
      (va: any) => parseInt(va.id) !== parseInt(currentAosID || "")
    );
    console.log("checkdisable", list_of_aos, filterdlist, currentAosID);
    const currentAosIDIndex = list_of_aos.findIndex(
      (va: any) => parseInt(va.id) === parseInt(currentAosID || "")
    );
    if (type === "next") {
      if (list_of_aos.length === 0 || list_of_aos.length === 1) {
        return true;
      } else {
        return currentAosIDIndex === list_of_aos.length - 1 ? true : false;
      }
    }
    if (type === "prev") {
      if (list_of_aos.length === 0 || list_of_aos.length === 1) {
        return true;
      } else {
        return currentAosIDIndex === 0 ? true : false;
      }
    }
  };
  //TODO :next prev
  // const handleAOS = (type: string) => {
  //   const list_of_aos =
  //     subjectClassRecDetails && subjectClassRecDetails[0]?.list_of_aos;
  //   const currentAosID = queryParams.get("aosID");
  //   const currentAosIDIndex = list_of_aos?.findIndex(
  //     (va: any) => va.id === parseInt(currentAosID || "")
  //   );

  //   console.log("handleAOS", currentAosIDIndex, list_of_aos);

  //   // Resetting selected module and file details
  //   setSelectedSubModule({ id: "", module: "" });
  //   setSelectedType("");
  //   setSelectedModuleTypeId("");
  //   setSelectedItems({});
  //   setFileId("");

  //   if (
  //     !list_of_aos ||
  //     currentAosIDIndex === undefined ||
  //     currentAosIDIndex < 0
  //   )
  //     return;

  //   const navigateToPage = (aosID: number) => {
  //     const newSearch = new URLSearchParams();
  //     newSearch.set("sid", `${subjectClassRecDetails[0]?.fk_subject_id}`);

  //     if (aosID < 0) {
  //       newSearch.set("crecId", `${aosID}`);
  //       navigate(`/class-recording?${newSearch.toString()}`);
  //     } else {
  //       newSearch.set("aosID", `${aosID}`);
  //       navigate(`/aos?${newSearch.toString()}`);
  //     }
  //   };

  //   if (type === "next") {
  //     if (currentAosIDIndex < list_of_aos.length - 1) {
  //       const nextIndex = currentAosIDIndex + 1;
  //       const nextAosID = list_of_aos[nextIndex]?.id;
  //       console.log("handleAOS next", currentAosIDIndex, nextIndex, nextAosID);
  //       navigateToPage(nextAosID);
  //     }
  //   } else if (type === "prev") {
  //     if (currentAosIDIndex > 0) {
  //       const prevIndex = currentAosIDIndex - 1;
  //       const prevAosID = list_of_aos[prevIndex]?.id;
  //       console.log("handleAOS prev", currentAosIDIndex, prevIndex, prevAosID);
  //       navigateToPage(prevAosID);
  //     }
  //   }
  // };

  // const handleAOS=(subject_id:any,aos_id:any)=>{
  //   const newSearch = new URLSearchParams();
  //   newSearch.set("sid", `${subject_id}`);
  //   newSearch.set("aosID", `${aos_id}`);
  //   navigate(`/aos?${newSearch.toString()}`);
  // }

  const SetRequiredData = (
    subjectClassRecDetails: any,
    classId: any,
    crecId: any,
    fileID: any
  ) => {
    if (!subjectClassRecDetails || !Array.isArray(subjectClassRecDetails) || subjectClassRecDetails.length === 0) {
      console.error("Invalid subjectClassRecDetails", subjectClassRecDetails);
      return;
    }

    const list_of_aos = subjectClassRecDetails[0]?.list_of_aos || [];
    const currentAosID = queryParams.get("crecId");

    if (list_of_aos.length === 0) {
      console.error("No AOS data available");
      return;
    }

    const currentAosIDIndex = list_of_aos.findIndex(
      (va: any) => va.id === parseInt(currentAosID || "", 10)
    );

    setPage((prev) => ({
      ...prev,
      currentPage: list_of_aos[currentAosIDIndex] || null,
      nextPage: list_of_aos[currentAosIDIndex + 1] || null,
      prevPage: list_of_aos[currentAosIDIndex - 1] || null,
    }));

    console.log("list_of_aos", JSON.stringify(list_of_aos));

    let subjectAOSDetail = subjectClassRecDetails[0]?.classes?.[0]?.class_recordings || [];
    const subjectAOSDetail2 = subjectClassRecDetails[0]?.recordings?.classes || [];

    subjectAOSDetail2.forEach((data: any) => {
      setSelectedSubModule({
        id: data?.id,
        module: data?.class_name,
      });
    });

    console.log("subjectAOSDetails", subjectAOSDetail);

    if (classId) {
      subjectAOSDetail = subjectAOSDetail.filter(
        (va: any) => parseInt(va.id, 10) === parseInt(classId, 10)
      );
    }

    if (subjectAOSDetail.length === 0) {
      console.error("No class recordings found");
      return;
    }

    console.log("Filtered subjectAOSDetail", subjectAOSDetail);

    subjectAOSDetail.forEach((data: any) => {
      let files = data.files || [];
      if (fileID) {
        files = files.filter((va: any) => parseInt(va.id, 10) === parseInt(fileID, 10));
      }

      if (files.length === 0) {
        console.error("No files available for the selected class");
        return;
      }

      console.log("Filtered files", files);

      const firstTopicId = data.id;
      const expandedIds = new Set<string>([
        `panel${firstTopicId}`,
        `panel${firstTopicId}-details`,
      ]);

      setExpanded(expandedIds);
      setSelectedItems({
        [`panel${firstTopicId}`]: files[0]?.id,
      });
      setSelectedSubModule({
        id: data?.id,
        module: data?.class_name,
      });
      setFileId(files[0]?.id);
      setFile_url(files[0]?.url);
      // SetThumbnail_url(files[0]?.thumbnail_url)
    });

    setLoadingData(false);
  };


  const handleAOS = (type: string) => {
    if (!subjectClassRecDetails || !subjectClassRecDetails.length) {
      console.error("subjectClassRecDetails is not available");
      return;
    }

    const list_of_aos = subjectClassRecDetails[0]?.list_of_aos;
    if (!list_of_aos || !list_of_aos.length) {
      console.error("list_of_aos is not available");
      return;
    }

    const currentAosID = queryParams.get("crecId");
    const currentAosIDIndex = list_of_aos.findIndex((va: any) => va.id === parseInt(currentAosID || ""));

    if (currentAosIDIndex === -1) {
      console.error("Current AOS ID not found in list_of_aos");
      return;
    }

    // Resetting selected module and file details
    setSelectedSubModule({ id: "", module: "" });
    setSelectedItem("");
    setSelectedType("");
    setSelectedItems({});
    setFileId("");

    const navigateToPage = (crecId: number) => {
      const newSearch = new URLSearchParams();
      newSearch.set("sid", `${subjectClassRecDetails[0]?.fk_subject_id}`);
      newSearch.set("aosID", `${crecId}`);

      navigate(`/aos?${newSearch.toString()}`);
    };

    if (type === "next") {
      if (currentAosIDIndex < list_of_aos.length - 1) {
        const nextIndex = currentAosIDIndex + 1;
        const nextAosID = list_of_aos[nextIndex]?.id;
        navigateToPage(nextAosID);
      }
    } else if (type === "prev") {
      if (currentAosIDIndex > 0) {
        const prevIndex = currentAosIDIndex - 1;
        const prevAosID = list_of_aos[prevIndex]?.id;
        navigateToPage(prevAosID);
      }
    }
  };



  const handleClassRecNav = (classId: any, fileID: any) => {
    const newSearch = new URLSearchParams();
    const cSubjectId = queryParams.get("sid");
    const crecId = queryParams.get("crecId");
    newSearch.set("sid", `${cSubjectId}`);
    newSearch.set("crecId", `${crecId}`);
    newSearch.set("classId", `${classId}`);
    newSearch.set("fileID", `${fileID}`);
    navigate(`/class-recording?${newSearch.toString()}`);
    setShowRightPanel(true);
  }

  const rgbColor = hexToRgb(subjectClassRecDetails[0]?.subject_colour);
  const opacity = 0.1;
  const handleDropdownSubject = (value: any) => {
    const newSearch = new URLSearchParams();
    newSearch.set("sid", value);
    // Push new URL with updated query parameter
    navigate(
      `/subject/${subjectClassRecDetails[0]?.sub_code}?${newSearch.toString()}`
    );
  };

  return (
    <Dashboard
      showPopover={true}
      selectedSubjectId={currentSubjectId}
      onClickCallback={(value) => handleDropdownSubject(value)}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        height={{ md: "calc(100vh - 76px)" }}
        overflow={{ md: "hidden" }}
      >
        {toast && (
          <ToastMessage
            type={toast.type as "success" | "error"}
            message={toast.message}
            onClose={() => setToast(null)}
            style={{
              position: "absolute",
              top: "85px",
            }}
          />
        )}
        
                {isMobile && <SubjectBtnBtmSheet currentSubjectId={currentSubjectId} />}
               
        <Box>
          <Grid container sx={{ height: "100%" }}>
            <Grid
              item
              md={4}
              sm={12}
              xs={12}
              style={{ display: isMobile && showRightPanel ? "none" : "block" }}
            >
              <Box
                sx={{
                  boxShadow: "-10px 0px 26px 0px rgba(0, 0, 0, 0.04) inset",
                }}
              >
                <Box
                  sx={{
                    background: `rgba(${rgbColor?.join(",")}, ${opacity})`,
                    padding: isMobile ? "22px 28px" : "25px 45px",
                  }}
                >
                  {loadingData ? (
                    <Skeleton
                      variant="rounded"
                      width={"100%"}
                      height={"35px"}
                    />
                  ) : (
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      sx={{ marginBottom: "10px" }}
                    >
                      <Breadcrumbs
                        sx={{
                          ".MuiBreadcrumbs-separator": { margin: "0 12px" },
                        }}
                        separator="›"
                        aria-label="breadcrumb"
                      >
                        {breadcrumbs.slice(0, breadcrumbs.length - 1)}
                        <Typography
                          sx={{
                            fontSize: 12,
                            color: "#333",
                            fontFamily: "Inter",
                            fontWeight: 600,
                            textTransform: "capitalize"
                          }}
                        >
                          <span style={{ textTransform: "uppercase" }}>
                            {page.currentPage?.aos_code || "AOS"}
                          </span>{" "}
                          {
                            page.currentPage?.area_of_study == null ? '' : <span>- {page.currentPage?.area_of_study}</span>
                          }
                        </Typography>
                      </Breadcrumbs>
                    </Box>
                  )}
                  <Box
                    sx={{
                      marginBottom: "12px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {loadingData ? (
                      <Skeleton
                        sx={{ marginTop: "15px" }}
                        variant="rounded"
                        width={"100%"}
                        height={"35px"}
                      />
                    ) : (
                      <>
                        {subjectClassRecDetails[0]?.subject_icon !== null && (
                          <Box
                            borderRadius={"50px"}
                            width={{ md: "35px", xs: "30px", sm: "30px" }}
                            height={{ md: "35px", xs: "30px", sm: "30px" }}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            sx={{
                              background:
                                subjectClassRecDetails[0]?.subject_colour,
                            }}
                          >
                            <img
                              src={subjectClassRecDetails[0]?.subject_icon}
                              alt="Subject Icon"
                              width={isMobile ? 15 : 23}
                              height={isMobile ? 15 : 23}
                              crossOrigin="anonymous"
                            />
                          </Box>
                        )}
                        {subjectClassRecDetails[0]?.subject_icon === null &&
                          subjectClassRecDetails[0]?.subject_icon !== null && (
                            <Box
                              borderRadius={"50px"}
                              width={{ md: "35px", xs: "30px", sm: "30px" }}
                              height={{ md: "35px", xs: "30px", sm: "30px" }}
                              display={"flex"}
                              alignItems={"baseline"}
                              justifyContent={"center"}
                              sx={{
                                background:
                                  subjectClassRecDetails[0]?.subject_colour,
                              }}
                            >
                              <img
                                src={subjectClassRecDetails[0]?.subject_icon}
                                alt="Subject Icon"
                                width={15}
                                height={15}
                                style={{ paddingTop: "10px" }}
                                crossOrigin="anonymous"
                              />
                            </Box>
                          )}
                        {subjectClassRecDetails[0]?.subject_icon === null &&
                          subjectClassRecDetails[0]?.subject_icon === null && (
                            <Box
                              borderRadius={"50px"}
                              width={{ md: "35px", xs: "30px", sm: "30px" }}
                              height={{ md: "35px", xs: "30px", sm: "30px" }}
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                              sx={{
                                background: "transparent",
                              }}
                            >
                              <Icons
                                name="bookOpen"
                                width={isMobile ? 30 : 40}
                                height={isMobile ? 30 : 40}
                              />
                            </Box>
                          )}
                        {subjectClassRecDetails &&
                          subjectClassRecDetails[0]?.list_of_aos.length > 1 && (
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <div
                                style={{
                                  transition: "opacity 0.1s ease",
                                  opacity: checkDisable("prev") ? 0.5 : 1,
                                  width: "28px",
                                  height: "28px",
                                }}
                              >
                                {!checkDisable("prev") && (
                                  <StyledIconButton
                                    disabled={checkDisable("prev")}
                                    onClick={() => handleAOS("prev")}
                                    aria-label="Previous"
                                  >
                                    <ChevronLeft style={{ color: "green" }} />
                                  </StyledIconButton>
                                )}
                              </div>
                              <CustomTypography
                                variant="caption"
                                isEmpty={page.prevPage === null}
                                onClick={() => handleAOS("prev")}
                                sx={{
                                  cursor: "pointer",
                                  opacity: page.prevPage === null ? 0.5 : 1,
                                  transition: "opacity 0s",
                                  '&.MuiTypography-root': {
                                    marginLeft: "0 !important",
                                    padding: "0 4px"
                                  }
                                }}
                              >
                                {page.prevPage?.aos_code || ""}
                                {/* "code 1" */}
                              </CustomTypography>
                              <CustomTypography
                                variant="caption"
                                isEmpty={page.nextPage === null}
                                onClick={() => handleAOS("next")}
                                sx={{
                                  cursor: "pointer",
                                  opacity: page.nextPage === null ? 0.5 : 1,
                                  transition: "opacity 0s",
                                  '&.MuiTypography-root': {
                                    marginLeft: "4px !important",
                                    padding: "0 4px"
                                  }
                                }}
                              >
                                {page.nextPage?.aos_code || ""}
                                {/* "code 2" */}
                              </CustomTypography>
                              <Box
                                sx={{
                                  transition: "opacity 0.1s ease",
                                  opacity: checkDisable("next") ? 0.5 : 1,
                                  width: "28px",
                                  height: "28px",
                                  "&.MuiBox-root": {
                                    marginLeft: "0px !important",
                                  },
                                }}
                              >
                                {!checkDisable("next") && (
                                  <StyledIconButton
                                    disabled={checkDisable("next")}
                                    onClick={() => handleAOS("next")}
                                    aria-label="Next"
                                  >
                                    <ChevronRight />
                                  </StyledIconButton>
                                )}
                              </Box>
                              {/* )} */}
                            </Stack>
                          )}
                      </>
                    )}
                  </Box>

                  {loadingData ? (
                    <Skeleton
                      sx={{ marginTop: "15px" }}
                      variant="rounded"
                      width={"100%"}
                      height={"35px"}
                    />
                  ) : (
                    <>
                      {subjectClassRecDetails &&
                        <div key={subjectClassRecDetails[0]?.recordings.id}>
                          <Typography
                            sx={{
                              marginBottom: "12px",
                              fontSize: 28,
                              color: "#333",
                              fontWeight: 600,
                              textTransform: "capitalize",
                            }}
                          >
                            {subjectClassRecDetails[0]?.recordings.name == null ? (
                              ""
                            ) : (
                              <span>{subjectClassRecDetails[0]?.recordings.name}</span>
                            )}
                          </Typography>
                          <Typography
                            sx={{
                              marginBottom: "12px",
                              fontSize: 16,
                              fontFamily: "Inter",
                              fontWeight: 400,
                            }}
                          >
                            {subjectClassRecDetails[0]?.recordings.name}
                          </Typography>
                        </div>
                      }
                    </>
                  )}
                </Box>
                <Box
                  sx={{
                    padding: "24px",
                    height: {
                      md: "100vh",
                      sm: "calc(100vh - 400px)",
                      xs: "calc(100vh - 400px)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      height: {
                        md: "calc(100vh - 385px)",
                        sm: "100%",
                        xs: "100%",
                      },
                      overflowY: { md: "scroll", sm: "scroll", xs: "scroll" },
                    }}
                  >
                    {loadingData ? (
                      <Skeleton
                        sx={{ marginTop: "15px" }}
                        variant="rounded"
                        width={"100%"}
                        height={"100%"}
                      />
                    ) : (
                      <>
                        {subjectClassRecDetails &&
                          subjectClassRecDetails[0]?.recordings?.classes?.map(
                            (sub_module: any, i: number) => (
                              <>
                                <Accordion
                                  key={sub_module.id + i}
                                  expanded={expanded.has(`panel${sub_module.id}`)}
                                  onChange={handleChange(`panel${sub_module.id}`)}
                                  sx={{
                                    border: "none",
                                    overflowY: "auto",
                                  }}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${sub_module.id}-content`}
                                    id={`panel${sub_module.id}-header`}
                                    sx={{
                                      borderRadius: "8px",
                                      border: "1px solid #EFEFEF",
                                      background: "#fff",
                                      padding: "16px 20px",
                                      backgroundColor: expanded.has(`panel${sub_module.id}`)
                                        ? "#FFEDD1"
                                        : "inherit",
                                      height: "auto",
                                      color: expanded.has(`panel${sub_module.id}`)
                                        ? "#333333"
                                        : "#696969",
                                      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":
                                      {
                                        transform: "rotate(0deg)",
                                      },
                                      ".MuiAccordionSummary-content": {
                                        margin: "0px !important",
                                      },
                                    }}
                                  >
                                    <Box
                                      display={"flex"}
                                      flexDirection={"column"}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: 16,
                                          fontFamily: "Inter",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {sub_module.class_name}`
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontSize: 14,
                                          fontFamily: "Inter",
                                          fontWeight: 400,
                                        }}
                                      >
                                        {sub_module.files.length}{" "}
                                        {sub_module.files.length === 1
                                          ? "Recording"
                                          : "Recordings"}
                                      </Typography>
                                    </Box>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    sx={{ borderRadius: "8px", padding: "0" }}
                                  >
                                    <Box>
                                      {sub_module?.files.map(
                                        (listItem: any, index: number) => (
                                          <Box
                                            key={index}
                                            onClick={() => handleClassRecNav(sub_module?.id, listItem?.id)}
                                            sx={{
                                              padding: "12px 20px",
                                              marginTop: "5px",
                                              fontSize: "14px",
                                              fontFamily: "Inter",
                                              fontWeight:
                                                selectedItems[`panel${sub_module.id}`] ===
                                                  `${listItem?.id}`
                                                  ? "600"
                                                  : "400",
                                              backgroundColor:
                                                selectedItems[`panel${sub_module.id}`] ===
                                                  `${listItem?.id}`
                                                  ? "#FFC071"
                                                  : "transparent",
                                              borderRadius: "8px",
                                              cursor: "pointer",
                                              display: "flex",
                                              alignItems: "center",
                                              textTransform: "capitalize",
                                              gap: "8px",
                                            }}
                                          >
                                            {selectedItems[`panel${sub_module.id}`] ===
                                              listItem?.id ? (
                                              <>
                                                <IconComponent
                                                  name="recordingBarBlackIcon"
                                                  fill={"#8E8D8A"}
                                                  width={16}
                                                  height={16}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <IconComponent
                                                  name="recordingBarIcon"
                                                  fill={"#8E8D8A"}
                                                  width={16}
                                                  height={16}
                                                />
                                              </>
                                            )}
                                            {`Week ${listItem?.week_no}`}
                                          </Box>
                                        )
                                      )}
                                    </Box>
                                  </AccordionDetails>
                                </Accordion>
                              </>
                            )
                          )}
                      </>
                    )}
                  </Box>
                  <Box
                    sx={{
                      padding: "12px 0",
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "15px",
                      position: "fixed",
                      bottom: 0,
                      left: "0",
                      right: { md: "inherit", sm: "0", xs: "0" },
                      background: "#fff",
                      boxShadow: "0px 4px 20px 0px rgba(45, 45, 45, 0.16)",
                      zIndex: 999,
                      [theme.breakpoints.up("md")]: {
                        display: "flex",
                        maxWidth: "33.33%",
                      },
                      [theme.breakpoints.down("sm")]: {
                        display: "flex",
                        maxWidth: "100%",
                      },
                    }}
                  >
                    <div>
                      <BorderText
                        text={"Expand all"}
                        onClick={handleExpandAll}
                      />
                    </div>
                    <Box sx={{ marginRight: "12px !important" }}>
                      <BorderText
                        text={"Collapse all"}
                        onClick={handleCollapseAll}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              md={8}
              sm={12}
              xs={12}
              style={{
                display: isMobile
                  ? showRightPanel
                    ? "block"
                    : "none"
                  : "block",
              }}
              sx={{
                "@media screen and (max-width:900px)": {
                  maxWidth: "100%",
                },
              }}
            >
              <Box
                sx={{
                  "@media screen and (max-width:1240px)": {
                    padding: "25px 10px",
                  },
                  padding: {
                    xs: "0px 0px",
                    md: "25px 45px",
                    sm: "12px 20px",
                  },
                }}
              >
                <div key={selectedSubModule.id}>
                  <div
                    style={{
                      height: "calc(100vh - 127px)",
                      overflowY: "scroll",
                    }}
                  >
                    {/* <BreadCrumbresComponent
                        subjectAOSDetails={subjectClassRecDetails}
                        selectedSubModule={selectedSubModule}
                        selectedType={selectedType}
                        setShowRightPanel={setShowRightPanel}
                      /> */}
                    <Typography
                      sx={{
                        fontSize: 18,
                        color: "#333",
                        fontFamily: "PolySans_Neutral",
                        fontWeight: 600,
                        lineHeight: "21px",
                        marginBottom: "16px",
                        display: { xs: "block", sm: "block", md: "none" },
                      }}
                    >
                      {selectedSubModule?.module.replace("-", "")}
                      {selectedType &&
                        " - " + selectedType.charAt(0).toUpperCase()}
                      {selectedType && selectedType.substring(1)}
                    </Typography>
                    <Box
                      sx={{
                        display: { md: "flex", sm: "none", xs: "none" },
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "24px",
                      }}
                    >
                      <Typography
                        variant="title1"
                        sx={{ fontSize: "22px", color: "#2B3258" }}
                      >
                        {selectedSubModule?.module.replace("-", "")}
                        {selectedType &&
                          " - " + selectedType.charAt(0).toUpperCase()}
                        {selectedType && selectedType.substring(1)}
                      </Typography>
                    </Box>
                    {/* <AccordionBtmClassSheet
                        bugDescription={bugDescription}
                        bottomDrawerOpen2={bottomDrawerOpen2}
                        setBottomDrawerOpen2={setBottomDrawerOpen2}
                        // markComplete={markComplete}
                        // handleMark={handleMark}
                        fileTaskId={markTaskId}
                        bugRepId={bugRepId}
                        bugTitle={bugTitle}
                        setToast={setToast}
                        attachment={attachment}
                        handleBugTitleChange={handleBugTitleChange}
                        handleBugDescriptionChange={handleBugDescriptionChange}
                        titleError={titleError}
                        descriptionError={descriptionError}
                        setAttachment={setAttachment}
                        setBugTitle={setBugTitle}
                        setBugDescription={setBugDescription}
                        selectedSubModule={selectedSubModule}
                        selectedType={selectedType}
                        subjectAOSDetails={subjectClassRecDetails}
                      /> */}
                    {currentSubjectId && clrecID && (
                      <Box id="videoSec">
                        <Box id="videoSec2">
                          <StudentClassRecording
                            topic_id={selectedSubModule.id}
                            class_id={clrecID}
                            bugRepId={bugRepId}
                            aosRecordingDetails={subjectClassRecDetails}
                            fk_subject_id={currentSubjectId}
                            selectedTopic={selectedSubModule}
                            // selectedModuleTypeId={selectedModuleTypeId}
                            setBugRepId={setBugRepId}
                            title={selectedSubModule?.module}
                            //   setRecordingData={setRecordingData}
                            //   setMarkComplete={setMarkComplete}
                            file_id={file_id}
                            file_url={file_url}
                            thumbnail_url={thumbnail_url}
                            transcript_path={transcript_path}
                            // selectedType={selectedType}
                            subjectAOSDetails={subjectClassRecDetails}
                            //   open={open}
                            //   openBottomSheet={openBottomSheet}
                            pageSelected={page}
                          />
                        </Box>
                      </Box>
                    )}
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dashboard>
  );
};


