import React, { useEffect, useState } from "react";
import Dashboard from "../../layouts/Dashboard";
import { Box, Grid } from "@mui/material";
import SubjectContentGrid from "../../components/Subject/SubjectContentGrid";
import CourseContentGrid from "../../components/Subject/CourseContentGrid";
import { getSubjectDetails } from "../../services/api/subject";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export const StudentSubject = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;
  const [subjectDetails, setSubjectDetails] = useState<any[]>([]);
  const [currentSubjectId, setCurrentSubjectId] = useState<string | undefined>();
  const [loadingSubject, setLoadingSubject] = useState(true);

  useEffect(() => {
    const subject_id = queryParams.get('sid')
    if (subject_id) {
      setCurrentSubjectId(subject_id)
      const getSubjectsDetails = async () => {
        try {
          const payload = {
            subject_id: subject_id,
          };
          const response = await getSubjectDetails(payload, verifyAccess.token);
          setSubjectDetails(response?.data.data);
          setLoadingSubject(false);
        } catch (error) {
          setLoadingSubject(false);
          console.error("Error fetching subject details:", error);
        }
      };
      getSubjectsDetails();


    }
  }, [location]);

  const handleDropdownSubject = (value: any) => {
    const newSearch = new URLSearchParams();
    newSearch.set('sid', value);
    // Push new URL with updated query parameter
    navigate(`?${newSearch.toString()}`);
  }
  function hexToRgb(hex: string): number[] | null {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (!result) {
      return null;
    }
    return [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16),
    ];
  }

  const rgbColor = hexToRgb(subjectDetails[0]?.subject_colour);
  const opacity = 0.1;

  return (
    <Dashboard
      showPopover={true}
      selectedSubjectId={currentSubjectId}
      onClickCallback={(value) => handleDropdownSubject(value)}
    >
      <Box display={"flex"} flexDirection={"column"} flexGrow={1}>
        <Grid container sx={{ height: { md: "calc(100vh - 80px);" } }}>
          <Grid
            item
            sm={12}
            xs={12}
            md={3.7}
            xl={3.5}
            sx={{ background: `rgba(${rgbColor?.join(",")}, ${opacity})` }}
          >
            <SubjectContentGrid
              subjectDetails={subjectDetails}
              loadingSubject={loadingSubject}
            />
          </Grid>
          <Grid item sm={12} xs={12} md={8.3} xl={8.5}>
            <CourseContentGrid
              subjectDetails={subjectDetails}
              loadingSubject={loadingSubject}
              // subject_id={2}
              subject_id={currentSubjectId}
            />
          </Grid>
        </Grid>
      </Box>
    </Dashboard>
  );
};
